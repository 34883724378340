import { FIELD_TYPES } from './enums';

// const env = process.env.REACT_APP_STAGING || 'PROD'; //possible values: UAE, PROD, DEV
const env = 'DEV'; //possible values: UAE, PROD, DEV

export const HOST_API = {
  UAE: 'https://uae-api.mobile.com.kz/api', // UAE PROD
  PROD: 'https://api.v2.mobile.com.kz/api', // PROD
  DEV: 'https://api.v2-dev.mobile.com.kz/api', // DEV
};

// @ts-ignore
export const API = HOST_API[env]; // BUILD API !!!
// @ts-ignore
export const IS_UAE = env === 'UAE';

 export const PRINT_API = 'https://testapi.office-mobile.com.kz' //DEV
//export const PRINT_API = 'https://api.mobile.com.kz'; // PROD

export const PHONE_REGEX_UA = /^\+?3?8?(0\d{9})$/;

export const COUNTRIES = [
  { id: 1, name: 'Казахстан', capital: 'Нур-Султан', currency: 'KZT', countryCode: '+7' },
  { id: 3, name: 'Узбекистан', capital: 'Ташкент', currency: 'UZS', countryCode: '+998' },
  { id: 8, name: 'UAE', capital: 'Abu Dhabi', currency: 'AED', countryCode: '+' },
];

export const CERTIFICATE_SOURCE_TYPES = {
  API_PARTNER: 'ApiPartner',
};

export const ROLE_DISPLAY_TYPES = {
  None: 'None',
  OnlyShop: 'OnlyShop', //[Description("Только Магазин")]
  AllShopInBranch: 'AllShopInBranch', //[Description("Вся сеть")]
  GroupShops: 'GroupShops', //[Description("Все магазины выбраных сетей")]
};

export const PARTNER_SALE_TYPES = {
  None: 'None',
  Online: 'Online', //[Description("Продажа в интернете")]
  Offline: 'Offline', //[Description("Продажа в магазине")]
};

export const notSelectedItem = {
  id: null,
  name: 'Не выбрано',
  displayName: {
    en: 'Not selected',
    ru: 'Не выбрано',
    kz: 'Таңдалмады',
  },
};

export enum PAGE_NAMES {
  STATISTIC_VIEW = 'Statistic.View',
  SALES_LIST = 'Sale.List',
  SALES_CREATE = 'Sale.Create',
  SALES_VIEW = 'Sale.View',
  SALES_REPORT = 'Sale.Report',
  EXCHANGE_LIST = 'Exchange.List',
  EXCHANGE_CREATE = 'Exchange.Create',
  EXCHANGE_VIEW = 'Exchange.View',
  EXCHANGE_REPORT = 'Exchange.Report',
  USER_LIST = 'User.List',
  USER_CREATE = 'User.Create',
  USER_EDIT = 'User.Edit',
  ROLE_LIST = 'Role.List',
  ROLE_CREATE = 'Role.Create',
  ROLE_EDIT = 'Role.Edit',
  REGISTRIES_VIEW = 'Registries.View',
  REPORT_LIST = 'Report.List',
  SHOP_BRANCH_LIST = 'ShopBranch.List',
  SHOP_BRANCH_CREATE = 'ShopBranch.Create',
  SHOP_BRANCH_EDIT = 'ShopBranch.Edit',
  SHOP_LIST = 'Shop.List',
  SHOP_CREATE = 'Shop.Create',
  SHOP_EDIT = 'Shop.Edit',
  COUNTRY_LIST = 'Country.List',
  COUNTRY_CREATE = 'Country.Create',
  COUNTRY_EDIT = 'Country.Edit',
  CITY_LIST = 'City.List',
  CITY_CREATE = 'City.Create',
  CITY_EDIT = 'City.Edit',
  DEVICE_LIST = 'Device.List',
  DEVICE_CREATE = 'Device.Create',
  DEVICE_EDIT = 'Device.Edit',
  DEVICE_BRAND_LIST = 'DeviceBrand.List',
  DEVICE_BRAND_CREATE = 'DeviceBrand.Create',
  DEVICE_BRAND_EDIT = 'DeviceBrand.Edit',
  CERTIFICATE_LIST = 'Certificate.List',
  CERTIFICATE_SERIES_LIST = 'CertificateSeries.List',
  CERTIFICATE_SERIES_CREATE = 'CertificateSeries.Create',
  CERTIFICATE_SERIES_EDIT = 'CertificateSeries.Edit',
  CERTIFICATE_EMISSION_LIST = 'CertificateEmission.List',
  PRICE_PERCENT_LIST = 'PricePercent.List',
  PRICE_PERCENT_CREATE = 'PricePercent.Create',
  PRICE_PERCENT_EDIT = 'PricePercent.Edit',
  CATEGORY_LIST = 'Category.List',
  CATEGORY_CREATE = 'Category.Create',
  CATEGORY_EDIT = 'Category.Edit',
  COMPENSATION_PERCENT_LIST = 'Compensation.List',
  COMPENSATION_PERCENT_CREATE = 'Compensation.Create',
  COMPENSATION_PERCENT_EDIT = 'Compensation.Edit',
  BONUSES_LIST = 'Bonuses.List',
  BONUSES_EDIT = 'Bonuses.Edit',
  PROMO_CODE_LIST = 'PromoCode.List',
  PROMO_CODE_CREATE = 'PromoCode.Create',
  PROMO_CODE_EDIT = 'PromoCode.Edit',
  CASHBACK_LIST = 'Cashback.List',
  CASHBACK_CREATE = 'Cashback.Create',
  CASHBACK_EDIT = 'Cashback.Edit',
  PROMOTION_LIST = 'Promotion.List',
  PROMOTION_CREATE = 'Promotion.Create',
  PROMOTION_EDIT = 'Promotion.Edit',
}

export const AVAILABLE_PAGES = [
  {
    label: 'STATISTIC.STATISTIC',
    items: [{ key: PAGE_NAMES.STATISTIC_VIEW, label: 'ROLES.VIEWING' }],
  },
  {
    label: 'SALES.TITLE',
    items: [
      { key: PAGE_NAMES.SALES_LIST, label: 'ROLES.LIST' },
      { key: PAGE_NAMES.SALES_CREATE, label: 'ROLES.CREATING' },
      { key: PAGE_NAMES.SALES_VIEW, label: 'ROLES.VIEWING' },
      { key: PAGE_NAMES.SALES_REPORT, label: 'ROLES.REPORT' },
    ],
  },
  {
    label: 'ROLES.EXCHANGE',
    items: [
      { key: PAGE_NAMES.EXCHANGE_LIST, label: 'ROLES.LIST' },
      { key: PAGE_NAMES.EXCHANGE_CREATE, label: 'ROLES.CREATING' },
      { key: PAGE_NAMES.EXCHANGE_VIEW, label: 'ROLES.VIEWING' },
      { key: PAGE_NAMES.EXCHANGE_REPORT, label: 'ROLES.REPORT' },
    ],
  },
  {
    label: 'ROLES.TITLE',
    items: [
      { key: PAGE_NAMES.ROLE_LIST, label: 'ROLES.LIST' },
      { key: PAGE_NAMES.ROLE_CREATE, label: 'ROLES.CREATING' },
      { key: PAGE_NAMES.ROLE_EDIT, label: 'ROLES.EDITING' },
    ],
  },
  {
    label: 'USERS.TITLE',
    items: [
      { key: PAGE_NAMES.USER_LIST, label: 'ROLES.LIST' },
      { key: PAGE_NAMES.USER_CREATE, label: 'ROLES.CREATING' },
      { key: PAGE_NAMES.USER_EDIT, label: 'ROLES.EDITING' },
    ],
  },
  { key: PAGE_NAMES.REGISTRIES_VIEW, label: 'NAVIGATION.REGISTER_LOADING' },
  { key: PAGE_NAMES.REPORT_LIST, label: 'NAVIGATION.REPORTS' },
  {
    label: 'SHOP_BRANCHES.TITLE',
    items: [
      { key: PAGE_NAMES.SHOP_BRANCH_LIST, label: 'ROLES.LIST' },
      { key: PAGE_NAMES.SHOP_BRANCH_CREATE, label: 'ROLES.CREATING' },
      { key: PAGE_NAMES.SHOP_BRANCH_EDIT, label: 'ROLES.EDITING' },
    ],
  },
  {
    label: 'SHOP.TITLE',
    items: [
      { key: PAGE_NAMES.SHOP_LIST, label: 'ROLES.LIST' },
      { key: PAGE_NAMES.SHOP_CREATE, label: 'ROLES.CREATING' },
      { key: PAGE_NAMES.SHOP_EDIT, label: 'ROLES.EDITING' },
    ],
  },
  {
    label: 'COUNTRY.TITLE',
    items: [
      { key: PAGE_NAMES.COUNTRY_LIST, label: 'ROLES.LIST' },
      { key: PAGE_NAMES.COUNTRY_CREATE, label: 'ROLES.CREATING' },
      { key: PAGE_NAMES.COUNTRY_EDIT, label: 'ROLES.EDITING' },
    ],
  },
  {
    label: 'CITY.TITLE',
    items: [
      { key: PAGE_NAMES.CITY_LIST, label: 'ROLES.LIST' },
      { key: PAGE_NAMES.CITY_CREATE, label: 'ROLES.CREATING' },
      { key: PAGE_NAMES.CITY_EDIT, label: 'ROLES.EDITING' },
    ],
  },
  {
    label: 'DEVICES.TITLE',
    items: [
      { key: PAGE_NAMES.DEVICE_LIST, label: 'ROLES.LIST' },
      { key: PAGE_NAMES.DEVICE_CREATE, label: 'ROLES.CREATING' },
      { key: PAGE_NAMES.DEVICE_EDIT, label: 'ROLES.EDITING' },
    ],
  },
  {
    label: 'DEVICES.BRANDS.TITLE',
    items: [
      { key: PAGE_NAMES.DEVICE_BRAND_CREATE, label: 'ROLES.CREATING' },
      { key: PAGE_NAMES.DEVICE_BRAND_LIST, label: 'ROLES.LIST' },
      { key: PAGE_NAMES.DEVICE_BRAND_EDIT, label: 'ROLES.EDITING' },
    ],
  },
  {
    label: 'GLOBAL.CERTIFICATES',
    items: [
      { key: PAGE_NAMES.CERTIFICATE_SERIES_LIST, label: 'ROLES.SERIES_LIST' },
      { key: PAGE_NAMES.CERTIFICATE_SERIES_CREATE, label: 'ROLES.SERIES_CREATING' },
      { key: PAGE_NAMES.CERTIFICATE_SERIES_EDIT, label: 'ROLES.SERIES_EDITING' },
      { key: PAGE_NAMES.CERTIFICATE_EMISSION_LIST, label: 'ROLES.EMISSION' },
      { key: PAGE_NAMES.CERTIFICATE_LIST, label: 'ROLES.CERT_CARDS' },
    ],
  },
  {
    label: 'BONUSES.TITLE',
    items: [
      { key: PAGE_NAMES.BONUSES_LIST, label: 'BONUSES.LIST' },
      { key: PAGE_NAMES.BONUSES_EDIT, label: 'BONUSES.MANUAL_MANAGEMENT' },
      { key: PAGE_NAMES.PROMO_CODE_LIST, label: 'PROMO_CODE.LIST' },
      { key: PAGE_NAMES.PROMO_CODE_CREATE, label: 'PROMO_CODE.CREATE' },
      { key: PAGE_NAMES.PROMO_CODE_EDIT, label: 'PROMO_CODE.EDIT' },
      { key: PAGE_NAMES.CASHBACK_LIST, label: 'CASHBACK.LIST' },
      { key: PAGE_NAMES.CASHBACK_CREATE, label: 'CASHBACK.CREATE' },
      { key: PAGE_NAMES.CASHBACK_EDIT, label: 'CASHBACK.EDIT' },
      { key: PAGE_NAMES.PROMOTION_LIST, label: 'PROMOTION.LIST' },
      { key: PAGE_NAMES.PROMOTION_CREATE, label: 'PROMOTION.CREATE' },
      { key: PAGE_NAMES.PROMOTION_EDIT, label: 'PROMOTION.EDIT' },
    ],
  },
  {
    label: 'GLOBAL.OTHER',
    items: [
      { key: PAGE_NAMES.PRICE_PERCENT_LIST, label: 'PERCENTAGE_PRICE.LIST' },
      { key: PAGE_NAMES.PRICE_PERCENT_CREATE, label: 'PERCENTAGE_PRICE.CREATING' },
      { key: PAGE_NAMES.PRICE_PERCENT_EDIT, label: 'PERCENTAGE_PRICE.EDITING' },
      { key: PAGE_NAMES.CATEGORY_LIST, label: 'CATEGORY.LIST' },
      { key: PAGE_NAMES.CATEGORY_CREATE, label: 'CATEGORY.CREATING' },
      { key: PAGE_NAMES.CATEGORY_EDIT, label: 'CATEGORY.EDITING' },
      { key: PAGE_NAMES.COMPENSATION_PERCENT_LIST, label: 'PERCENTAGE_COMPENSATION.LIST' },
      { key: PAGE_NAMES.COMPENSATION_PERCENT_CREATE, label: 'PERCENTAGE_COMPENSATION.CREATING' },
      { key: PAGE_NAMES.COMPENSATION_PERCENT_EDIT, label: 'PERCENTAGE_COMPENSATION.EDITING' },
    ],
  },
];

export const AVAILABLE_SEARCH_CONFIG = [
  // { name: 'TimeZone', type: FIELD_TYPES.SELECT, items: timeZones },
  { name: 'from', type: FIELD_TYPES.DATE },
  { name: 'to', type: FIELD_TYPES.DATE },
  { name: 'typeDate', type: FIELD_TYPES.SELECT, dataToLoad: 'dateTypes' },
  { name: 'certificateID', type: FIELD_TYPES.CERTIFICATE_ID },
  { name: 'customerIIN', type: FIELD_TYPES.NUMBER },
  { name: 'customerINN', type: FIELD_TYPES.NUMBER },
  { name: 'customerPINFL', type: FIELD_TYPES.NUMBER },
  { name: 'contactBuyer', type: FIELD_TYPES.TEXT },
  { name: 'certificateSaleShopID', type: FIELD_TYPES.SELECT, dataToLoad: 'certificateSaleShops' },
  { name: 'countryID', type: FIELD_TYPES.SELECT, dataToLoad: 'countries' },
  { name: 'includeArchiveRows', type: FIELD_TYPES.BOOLEAN },
  { name: 'preApproveSearch', type: FIELD_TYPES.BOOLEAN },
  { name: 'returned', type: FIELD_TYPES.BOOLEAN },
];

export const EXCHANGE_DETAILS_SEARCH_CONFIG = [
  { name: 'from', type: FIELD_TYPES.DATE },
  { name: 'to', type: FIELD_TYPES.DATE },
  { name: 'typeDate', type: FIELD_TYPES.SELECT, dataToLoad: 'dateTypes' },
  { name: 'certificateID', type: FIELD_TYPES.CERTIFICATE_ID },
  { name: 'customerIIN', type: FIELD_TYPES.NUMBER },
  { name: 'customerINN', type: FIELD_TYPES.NUMBER },
  { name: 'customerPINFL', type: FIELD_TYPES.NUMBER },
  { name: 'contactBuyer', type: FIELD_TYPES.TEXT },
];

export const SALE_DETAILS_SEARCH_CONFIG = [
  { name: 'from', type: FIELD_TYPES.DATE },
  { name: 'to', type: FIELD_TYPES.DATE },
  { name: 'typeDate', type: FIELD_TYPES.SELECT, dataToLoad: 'dateTypes' },
  { name: 'certificateID', type: FIELD_TYPES.CERTIFICATE_ID },
];

export const EXCHANGE_DETAILS_FIELDS = [
  { name: 'orderId', type: FIELD_TYPES.DATE },
  { name: 'certificateID', type: FIELD_TYPES.CERTIFICATE_ID },
  { name: 'saleType', type: FIELD_TYPES.DATE },
  { name: 'certificateSaleDate', type: FIELD_TYPES.SELECT },
  { name: 'certificateUsedSaleDate', type: FIELD_TYPES.CERTIFICATE_ID },
  { name: 'merchandiseCost', type: FIELD_TYPES.NUMBER },
  { name: 'categoryType', type: FIELD_TYPES.NUMBER },
  { name: 'dictDeviceBrandID', type: FIELD_TYPES.NUMBER },
  { name: 'dictDevice', type: FIELD_TYPES.TEXT },
  { name: 'calculationCertificateCost', type: FIELD_TYPES.SELECT },
  { name: 'certificateCost', type: FIELD_TYPES.SELECT },
  { name: 'managerNames', type: FIELD_TYPES.BOOLEAN },
  { name: 'managerShopBranch', type: FIELD_TYPES.BOOLEAN },
  { name: 'managerEMail', type: FIELD_TYPES.BOOLEAN },
  { name: 'managerPhone', type: FIELD_TYPES.BOOLEAN },
  { name: 'deviceIMEI', type: FIELD_TYPES.BOOLEAN },
  { name: 'manualIMEIInput', type: FIELD_TYPES.BOOLEAN },
  { name: 'firstNameBuyer', type: FIELD_TYPES.BOOLEAN },
  { name: 'lastNameBuyer', type: FIELD_TYPES.BOOLEAN },
  { name: 'contactBuyer', type: FIELD_TYPES.BOOLEAN },
  { name: 'customerIIN', type: FIELD_TYPES.BOOLEAN },
  { name: 'customerINN', type: FIELD_TYPES.BOOLEAN },
  { name: 'customerPINFL', type: FIELD_TYPES.NUMBER },
  { name: 'deviceUnusableDeviceReasonID', type: FIELD_TYPES.NUMBER },
  { name: 'buyerEmail', type: FIELD_TYPES.BOOLEAN },
  { name: 'statusPayment', type: FIELD_TYPES.BOOLEAN },
  { name: 'printStatement', type: FIELD_TYPES.BOOLEAN },
  { name: 'sendStatement', type: FIELD_TYPES.BOOLEAN },
  { name: 'deviceWarrantyCard', type: FIELD_TYPES.BOOLEAN },
  { name: 'devicePackaging', type: FIELD_TYPES.BOOLEAN },
  { name: 'deviceAccessories', type: FIELD_TYPES.BOOLEAN },
  { name: 'approved', type: FIELD_TYPES.BOOLEAN },
  { name: 'managerComment', type: FIELD_TYPES.TEXT },
  { name: 'copySalesReceipt', type: FIELD_TYPES.TEXT },
  { name: 'copyCertificate', type: FIELD_TYPES.TEXT },
  { name: 'copyDocument', type: FIELD_TYPES.TEXT },
  { name: 'damageDevicePhotos1', type: FIELD_TYPES.TEXT },
  { name: 'damageDevicePhotos2', type: FIELD_TYPES.TEXT },
  { name: 'damageDevicePhotos3', type: FIELD_TYPES.TEXT },
  { name: 'imeIphotoResource', type: FIELD_TYPES.TEXT },
  { name: 'managerPhoto', type: FIELD_TYPES.TEXT },
  { name: 'warrantyCardPhoto', type: FIELD_TYPES.TEXT },
  { name: 'printActOfAcceptanceDevice', type: FIELD_TYPES.BUTTON },
];

export const SALE_DETAILS_FIELDS = [
  { name: 'orderId', type: FIELD_TYPES.DATE },
  // { name: 'saleType', type: FIELD_TYPES.DATE },
  { name: 'certificateID', type: FIELD_TYPES.CERTIFICATE_ID },
  { name: 'certificateSaleDate', type: FIELD_TYPES.SELECT },
  { name: 'certificateUsedSaleDate', type: FIELD_TYPES.DATE },
  { name: 'merchandiseCost', type: FIELD_TYPES.NUMBER },
  { name: 'categoryType', type: FIELD_TYPES.NUMBER },
  { name: 'dictDeviceBrandID', type: FIELD_TYPES.NUMBER },
  { name: 'dictDevice', type: FIELD_TYPES.TEXT },
  { name: 'calculationCertificateCost', type: FIELD_TYPES.BOOLEAN },
  { name: 'certificateCost', type: FIELD_TYPES.SELECT },
  { name: 'managerNames', type: FIELD_TYPES.BOOLEAN },
  { name: 'managerShopBranch', type: FIELD_TYPES.BOOLEAN },
  { name: 'managerEMail', type: FIELD_TYPES.BOOLEAN },
  { name: 'managerPhone', type: FIELD_TYPES.BOOLEAN },
  { name: 'deviceIMEI', type: FIELD_TYPES.BOOLEAN },
  { name: 'firstNameBuyer', type: FIELD_TYPES.BOOLEAN },
  { name: 'lastNameBuyer', type: FIELD_TYPES.BOOLEAN },
  { name: 'contactBuyer', type: FIELD_TYPES.BOOLEAN },
  { name: 'customerIIN', type: FIELD_TYPES.BOOLEAN },
  { name: 'customerINN', type: FIELD_TYPES.BOOLEAN },
  { name: 'customerPINFL', type: FIELD_TYPES.NUMBER },
  { name: 'buyerEmail', type: FIELD_TYPES.BOOLEAN },
  { name: 'additionalPaymentStatus', type: FIELD_TYPES.BOOLEAN },
  { name: 'printStatement', type: FIELD_TYPES.BOOLEAN },
  { name: 'returnDate', type: FIELD_TYPES.DATE },
  { name: 'sendSMS', type: FIELD_TYPES.DATE },
  { name: 'sendStatement', type: FIELD_TYPES.BOOLEAN },
  { name: 'isCreationDateEditable', type: FIELD_TYPES.BOOLEAN },
];

export const CERTIFICATE_SALE_REPORT_FIELDS = [
  { name: 'UICertificateSeries', checked: true },
  { name: 'UICertificateID', checked: true },
  { name: 'CertificateSaleShopName', checked: true },
  { name: 'UISubShop', checked: true },
  { name: 'ManagerShopBranchCity', checked: true },
  { name: 'ManagerShopBranchAddress', checked: true },
  { name: 'UICertificateSaleDate', checked: true },
  { name: 'MerchandiseCost', checked: true },
  { name: 'CertificateCost', checked: true },
  { name: 'ManagerNames', checked: true },
  { name: 'ManagerPhone', checked: true },
  { name: 'ManagerEMail', checked: true },
  { name: 'DeviceIMEI', checked: true },
  { name: 'UIBuyerNames', checked: true },
  { name: 'UICustomerPINFL', checked: true },
  { name: 'CustomerIIN', checked: true },
  { name: 'UIBuyerContact', checked: true },
  { name: 'BuyerEmail', checked: true },
  { name: 'Id', checked: true },
  { name: 'CreditAgreementNumber', checked: true },
  { name: 'DeviceName', checked: true },
  { name: 'CategoryName', checked: true },
  { name: 'CertificateUsedSaleDate', checked: true },
  { name: 'IsCancelled', checked: true },
  { name: 'ReturnDate', checked: true },
  { name: 'ManagerIIN', checked: true },
  { name: 'BankRegistryNumber', checked: true },
  { name: 'BankRegistryUploadDate', checked: true },
  { name: 'OrderId', checked: true },
  { name: 'SaleTypeDescription', checked: true },
  { name: 'SaleFlowStatusDescription', checked: true },
  { name: 'PartnerSaleTypeDescription', checked: true },
];

export const OPERATOR_DATA_REPORT_FIELDS = [
  { name: 'UICertificateSeries', checked: true },
  { name: 'UICertificateID', checked: true },
  { name: 'CertificateSaleShopName', checked: true },
  { name: 'UICertificateSaleDate', checked: true },
  { name: 'CertificateCost', checked: true },
  { name: 'MerchandiseCost', checked: true },
  { name: 'ManagerNames', checked: true },
  { name: 'ManagerEMail', checked: true },
  { name: 'UIQueryFillDate', checked: true },
  { name: 'ManagerShopBranchCity', checked: true },
  { name: 'ManagerShopBranchAddress', checked: true },
  { name: 'CustomerSelectedShopBranch', checked: true },
  { name: 'UISubShop', checked: true },
  { name: 'UICustomerPINFL', checked: true },
  { name: 'BuyerEmail', checked: true },
  { name: 'DeviceIDString', checked: true },
  { name: 'DeviceIMEI', checked: true },
  { name: 'ContactBuyer', checked: true },
  { name: 'DeviceUnusableDeviceReasonString', checked: true },
  { name: 'DeviceDamageCausesDescription', checked: true },
  { name: 'DeviceWarrantyCard', checked: true },
  { name: 'DevicePackaging', checked: true },
  { name: 'DeviceAccessories', checked: true },
  { name: 'DeviceApproved', checked: true },
  { name: 'UIApproved', checked: true },
  { name: 'UIApprovedDate', checked: true },
  { name: 'RejectionReason', checked: true },
  { name: 'UIRejectionDate', checked: true },
  { name: 'ClarifyComment', checked: true },
  { name: 'UIClarifyDate', checked: true },
  { name: 'CompensationAmount', checked: true },
  { name: 'CompensationAmountAdmin', checked: true },
  { name: 'AdminComment', checked: true },
  { name: 'ManagerComment', checked: true },
  { name: 'StatusUserCertificatName', checked: true },
  { name: 'PartnerSaleTypeDescription', checked: true },
];

export const PaymentStatus = {
  0: { ru: 'Не определен', en: 'Draft' },
  1: { ru: 'Успешно', en: 'Successful' },
  2: { ru: 'Неудачный', en: 'Failed' },
  3: { ru: 'Ожидает возвращения', en: 'Sent on refund' },
  4: { ru: 'Возвращено', en: 'Refund' },
  5: { ru: 'Сменено карту', en: 'Cart change' },
};
