// ----------------------------------------------------------------------

import PercentageCompensationList from '../sections/@dashboard/percentage-compensation-service/list';

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  register: path(ROOTS_AUTH, '/register'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  verify: path(ROOTS_AUTH, '/verify'),
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page404: '/404',
  page500: '/500',
  components: '/components',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
    stats: path(ROOTS_DASHBOARD, '/stats'),
    report: path(ROOTS_DASHBOARD, '/report'),
    print: path(ROOTS_DASHBOARD, '/print'),
    sales: path(ROOTS_DASHBOARD, '/sales/list'),
    exchange: path(ROOTS_DASHBOARD, '/exchange/list'),
    registerLoading: path(ROOTS_DASHBOARD, '/register-loading'),
    ecommerce: path(ROOTS_DASHBOARD, '/ecommerce'),
    analytics: path(ROOTS_DASHBOARD, '/analytics'),
    banking: path(ROOTS_DASHBOARD, '/banking'),
    booking: path(ROOTS_DASHBOARD, '/booking'),
  },
  sales: {
    create: path(ROOTS_DASHBOARD, '/sales/create'),
    edit: path(ROOTS_DASHBOARD, '/sales/edit'),
  },
  exchange: {
    create: path(ROOTS_DASHBOARD, '/exchange/create'),
    edit: path(ROOTS_DASHBOARD, '/exchange/edit'),
  },
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    partnerList: path(ROOTS_DASHBOARD, '/user/partner/list'),
    packageList: path(ROOTS_DASHBOARD, '/user/package/list'),
    roles: {
      root: path(ROOTS_DASHBOARD, '/user/roles'),
      list: path(ROOTS_DASHBOARD, '/user/roles/list'),
      new: path(ROOTS_DASHBOARD, '/user/roles/new'),
    },
    newUser: path(ROOTS_DASHBOARD, '/user/new'),
    editById: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
    account: path(ROOTS_DASHBOARD, '/user/account'),
    payment: path(ROOTS_DASHBOARD, '/user/payment'),
    customerConnection: path(ROOTS_DASHBOARD, '/user/customer-connection'),
  },
  shopBranch: {
    root: path(ROOTS_DASHBOARD, '/shop-branch'),
    list: path(ROOTS_DASHBOARD, '/shop-branch/list'),
    new: path(ROOTS_DASHBOARD, '/shop-branch/new'),
  },
  shops: {
    root: path(ROOTS_DASHBOARD, '/shops'),
    list: path(ROOTS_DASHBOARD, '/shops/list'),
    new: path(ROOTS_DASHBOARD, '/shops/new'),
  },
  country: {
    root: path(ROOTS_DASHBOARD, '/country'),
    list: path(ROOTS_DASHBOARD, '/country/list'),
    new: path(ROOTS_DASHBOARD, '/country/new'),
  },
  city: {
    root: path(ROOTS_DASHBOARD, '/city'),
    list: path(ROOTS_DASHBOARD, '/city/list'),
    new: path(ROOTS_DASHBOARD, '/city/new'),
  },
  mail: {
    root: path(ROOTS_DASHBOARD, '/mail'),
    all: path(ROOTS_DASHBOARD, '/mail/all'),
  },
  chat: {
    root: path(ROOTS_DASHBOARD, '/chat'),
    new: path(ROOTS_DASHBOARD, '/chat/new'),
    conversation: path(ROOTS_DASHBOARD, '/chat/:conversationKey'),
  },
  calendar: path(ROOTS_DASHBOARD, '/calendar'),
  kanban: path(ROOTS_DASHBOARD, '/kanban'),
  eCommerce: {
    root: path(ROOTS_DASHBOARD, '/e-commerce'),
    shop: path(ROOTS_DASHBOARD, '/e-commerce/shop'),
    product: path(ROOTS_DASHBOARD, '/e-commerce/product/:name'),
    productById: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-air-force-1-ndestrukt'),
    list: path(ROOTS_DASHBOARD, '/e-commerce/list'),
    newProduct: path(ROOTS_DASHBOARD, '/e-commerce/product/new'),
    editById: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-blazer-low-77-vintage/edit-create'),
    checkout: path(ROOTS_DASHBOARD, '/e-commerce/checkout'),
    invoice: path(ROOTS_DASHBOARD, '/e-commerce/invoice'),
  },
  blog: {
    root: path(ROOTS_DASHBOARD, '/blog'),
    posts: path(ROOTS_DASHBOARD, '/blog/posts'),
    post: path(ROOTS_DASHBOARD, '/blog/post/:title'),
    postById: path(ROOTS_DASHBOARD, '/blog/post/apply-these-7-secret-techniques-to-improve-event'),
    newPost: path(ROOTS_DASHBOARD, '/blog/new-post'),
  },
  devices: {
    root: path(ROOTS_DASHBOARD, '/devices'),
    list: path(ROOTS_DASHBOARD, '/devices/list'),
    new: path(ROOTS_DASHBOARD, '/devices/new'),
    brand: {
      root: path(ROOTS_DASHBOARD, '/devices/brand'),
      list: path(ROOTS_DASHBOARD, '/devices/brand/list'),
      new: path(ROOTS_DASHBOARD, '/devices/brand/new'),
    },
  },
  certificates: {
    root: path(ROOTS_DASHBOARD, '/certificate'),
    series: {
      root: path(ROOTS_DASHBOARD, '/certificate/series'),
      new: path(ROOTS_DASHBOARD, '/certificate/series/new'),
      list: path(ROOTS_DASHBOARD, '/certificate/series/list'),
    },
    deviceBrandList: path(ROOTS_DASHBOARD, '/devices/brand-list'),
    newDeviceBrand: path(ROOTS_DASHBOARD, '/devices/new-brand'),
  },
  bonus: {
    root: path(ROOTS_DASHBOARD, '/bonus'),
    list: path(ROOTS_DASHBOARD, '/bonus/list'),
    manualManagement: path(ROOTS_DASHBOARD, '/bonus/manual_management'),
    promoCode: {
      root: path(ROOTS_DASHBOARD, '/bonus/promo_code'),
      list: path(ROOTS_DASHBOARD, '/bonus/promo_code/list'),
      new: path(ROOTS_DASHBOARD, '/bonus/promo_code/new'),
    },
    cashback: {
      root: path(ROOTS_DASHBOARD, '/bonus/cashback'),
      list: path(ROOTS_DASHBOARD, '/bonus/cashback/list'),
      new: path(ROOTS_DASHBOARD, '/bonus/cashback/new'),
    },
    promotion: {
      root: path(ROOTS_DASHBOARD, '/bonus/promotion'),
      list: path(ROOTS_DASHBOARD, '/bonus/promotion/list'),
      new: path(ROOTS_DASHBOARD, '/bonus/promotion/new'),
    },
  },
  other: {
    root: path(ROOTS_DASHBOARD, '/other/percentage-price'),
    percentagePrice: {
      root: path(ROOTS_DASHBOARD, '/other/percentage-price'),
      new: path(ROOTS_DASHBOARD, '/other/percentage-price/new'),
      list: path(ROOTS_DASHBOARD, '/other/percentage-price/list'),
    },
    category: {
      list: path(ROOTS_DASHBOARD, '/other/category/list'),
      new: path(ROOTS_DASHBOARD, '/other/category/new'),
      edit: path(ROOTS_DASHBOARD, '/other/category'),
    },
    categoryType: {
      list: path(ROOTS_DASHBOARD, '/other/category-type/list'),
      new: path(ROOTS_DASHBOARD, '/other/category-type/new'),
      edit: path(ROOTS_DASHBOARD, '/other/category-type'),
    },
    percentageCompensation: {
      root: path(ROOTS_DASHBOARD, '/other/percentage-compensation'),
      new: path(ROOTS_DASHBOARD, '/other/percentage-compensation/new'),
      list: path(ROOTS_DASHBOARD, '/other/percentage-compensation/list'),
    },
  },
};

export const PATH_APPS = {
  apple: 'https://apps.apple.com/us/app/keep-talk-service/id1511545834',
  android: 'https://play.google.com/store/apps/details?id=kz.com.mobile.apps.intooDeviceTesting',
  site: 'https://keeptalkservice.com',
  telegram: 'https://t.me/MobProBot',
};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';
