import { useSelector } from 'react-redux';
import _ from 'lodash';
import * as xlsx from 'xlsx';
import { API, HOST_API } from '../constants/constants';

export default function useHelper() {
  const { user } = useSelector((state: any) => state.user);

  function hasUserAccess(acceptRoles: string[]) {
    if (!acceptRoles) {
      return true;
    }
    if (user) {
      const { navigationConfig }: any = user.role;
      return _.some(acceptRoles, (item: string) => navigationConfig.includes(item));
    }
    return false;
  }

  function toBase64(file: any): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        if (typeof reader.result === 'string') {
          const b64 = reader.result && reader.result.replace(/^data:.+;base64,/, '');
          resolve(b64);
        }
      };
      reader.onerror = (error) => reject(error);
    });
  }

  const normalizeData = (json: any) => {
    const result: { key: string; value: string }[] = [];
    Object.keys(json)?.forEach((key: any) => {
      if (typeof json[key] === 'object') {
        result.push(...normalizeData(json[key]));
      } else {
        result.push({ key, value: json[key] });
      }
    });
    return result;
  };

  const generateXLS = (data: any) => {
    const normalizedData = normalizeData(data);

    console.log(normalizedData);
    const ws = xlsx.utils.json_to_sheet(normalizedData);
    const wb = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Sheet1');
    xlsx.writeFile(wb, `Переводы админка.xlsx`);
  };

  function getFileData(data: FormData) {
    if (data) {
      const file = data.get('data');
      if (file && typeof file !== 'string') {
        return {
          name: file.name,
          type: file.type,
        };
      }
      return { name: 'file', type: '' };
    }
    return { name: '', type: '' };
  }

  function isUAE() {
    return API === HOST_API.UAE;
  }

  return {
    hasUserAccess,
    toBase64,
    getFileData,
    isUAE,
    generateXLS,
  };
}
